import request from '@/utils/request'

const declarationApi = {
  declaration_list: '/declaration/',
  declaration_create: '/declaration/',
  declaration_read: '/declaration/',
  declaration_update: '/declaration/',
  declaration_delete: '/declaration/'
}

/**
 * 列表
 */
export function declaration_list (parameter) {
  return request({
    url: declarationApi.declaration_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function declaration_create (parameter) {
  return request({
    url: declarationApi.declaration_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 详情
 */
export function declaration_template_read (declaration_id) {
  return request({
    url: declarationApi.declaration_read + declaration_id + '/',
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
/**
 * xiugai
 */
export function declaration_update (parameter, declaration_id) {
  return request({
    url: declarationApi.declaration_update + declaration_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function declaration_delete (declaration_id) {
  return request({
    url: declarationApi.declaration_delete + declaration_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
