<template>
  <a-modal
    title="新建"
    :width="1080"
    :visible="visible"
    :confirmLoading="loading"
    @ok="handleSubmit"
    @cancel="() => { $emit('cancel') }"
  >
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <a-row :gutter="[16,16]">
          <a-col :span="24">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 80%">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import pick from 'lodash.pick'

// 表单字段
const fields = ['id', 'start_time', 'end_time']
export default {
  components: {},
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    model: {
      type: Object,
      default: () => null
    }
  },
  data () {
    this.formLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      }
    }
    return {
      color: '#3f3f3f',
      form: this.$form.createForm(this),
      dictionaries: this.$Dictionaries
    }
  },
  created () {
    console.log('custom modal created', this.form)
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))
    // 当 model 发生改变时，为表单设置值
    this.model && this.form.setFieldsValue(pick(this.model, fields))
  },
  methods: {
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      fields.forEach(v => this.form.getFieldDecorator(v))
      this.form.setFieldsValue({
        start_time: dateStrings[0]
      })
      this.form.setFieldsValue({
        end_time: dateStrings[1]
      })
    },
    handleSubmit () {
      setTimeout(() => {
        this.$emit('ok')
      })
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    }
  }
}
</script>
